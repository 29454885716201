import styled from 'styled-components';
import { Menu } from 'antd';

const { Item } = Menu;

const MenuContainer = styled(Menu)``;

const MenuItem = styled(Item)``;

export { MenuContainer, MenuItem };
